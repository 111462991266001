import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArticleIcon from "@mui/icons-material/Article";
import GroupsIcon from "@mui/icons-material/Groups";
import HandshakeIcon from "@mui/icons-material/Handshake";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import styles from "../../assets/styles/LinkSection.module.css";
import { Link } from "react-router-dom";
const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const LinkSection = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Customer testimonials",
    paragraph:
      "Vitae aliquet nec ullamcorper sit amet risus nullam eget felis semper quis lectus nulla at volutpat diam ut venenatis tellus—in ornare.",
  };

  return (
    <section
      {...props}
      // className={outerClasses}
      className={styles.section}
    >
      <div
        // sectionLinkArea hidden_xs scrolling
        className={`${styles.sectionLinkArea} ${styles.hidden_xs} ${styles.scrolling}`}
      >
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.col_sm_3}>
              <a
                href="#Services"
                className={`${styles.sectionLink} ${styles.bg_color_1}`}
                id="coursesLink"
              >
                <i
                  className={`${styles.fa} ${styles.fa_file_text_o} ${styles.linkIcon} ${styles.border_color_1}`}
                  aria-hidden="true"
                >
                  <MiscellaneousServicesIcon fontSize="large" />
                </i>
                <span className={styles.linkText}>Services</span>
                <i
                  className={`${styles.fa} ${styles.fa_chevron_down}  ${styles.locateArrow}`}
                  aria-hidden="true"
                >
                  <KeyboardArrowDownIcon />
                </i>
              </a>
            </div>
            <div className={styles.col_sm_3}>
              <a
                href="#News"
                className={`${styles.sectionLink} ${styles.bg_color_4}`}
                id="newsLink"
              >
                <i
                  className={`${styles.fa} ${styles.fa_calendar} ${styles.linkIcon} ${styles.border_color_4}`}
                  aria-hidden="true"
                >
                  <ArticleIcon fontSize="large" />
                </i>
                <span className={styles.linkText}>News</span>
                <i
                  className={`${styles.fa} ${styles.fa_chevron_down}  ${styles.locateArrow}`}
                  aria-hidden="true"
                >
                  <KeyboardArrowDownIcon />
                </i>
              </a>
            </div>

            <div className={styles.col_sm_3}>
              <a
                href="#ourTeam"
                className={`${styles.sectionLink} ${styles.bg_color_3}`}
                id="galleryLink"
              >
                <i
                  className={`${styles.fa} ${styles.fa_picture_o} ${styles.linkIcon} ${styles.border_color_3}`}
                  aria-hidden="true"
                >
                  <GroupsIcon fontSize="large" />
                </i>
                <span className={styles.linkText}>Team</span>
                <i
                  className={`${styles.fa} ${styles.fa_chevron_down}  ${styles.locateArrow}`}
                  aria-hidden="true"
                >
                  <KeyboardArrowDownIcon />
                </i>
              </a>
            </div>
            <div className={styles.col_sm_3}>
              <a
                href="#insurance-partners"
                className={`${styles.sectionLink} ${styles.bg_color_2}`}
                id="teamLink"
              >
                <i
                  className={`${styles.fa} ${styles.fa_users} ${styles.linkIcon} ${styles.border_color_2}`}
                  aria-hidden="true"
                >
                  <HandshakeIcon fontSize="large" />
                </i>
                <span className={styles.linkText}>Partners</span>
                <i
                  className={`${styles.fa} ${styles.fa_chevron_down}  ${styles.locateArrow}`}
                  aria-hidden="true"
                >
                  <KeyboardArrowDownIcon />
                </i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

LinkSection.propTypes = propTypes;
LinkSection.defaultProps = defaultProps;

export default LinkSection;
