import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import sections
import axios from "axios";
import AllServices from "../components/sections/AllServices";

const Services = () => {
  const params = useParams();

  const [services, setServices] = useState([]);

  const getServices = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/services/get`, {
        params: {},
      })
      .then(function (response) {
        setServices(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  useEffect(() => {
    getServices();
  }, []);

  return (
    <>
      <AllServices services={services} />
    </>
  );
};

export default Services;
