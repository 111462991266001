import React, { useEffect, useState } from "react";
// import sections
import Hero from "../components/sections/Hero";
import Carousel from "react-material-ui-carousel";
import useMediaQuery from "@mui/material/useMediaQuery";
import Image from "../components/elements/Image";
import axios from "axios";
import NewsBrief from "../components/sections/NewsBrief";
import FeaturesSplit from "../components/sections/FeaturesSplit";
import LinkSection from "../components/sections/LinkSection";
import ServicesSection from "../components/sections/ServicesSection";
import ContactBanner from "../components/sections/ContactBanner";
import EmployeesCarousel from "../components/sections/EmployeesCarousel";
import Partners from "../components/sections/Partners";

const Home = () => {
  const matches = useMediaQuery("(max-width:600px)");

  const [loading, setLoading] = useState("");
  const [items, setItems] = useState([]);

  const getPageData = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/pages/get`, {
        params: {
          name: "Home",
        },
      })
      .then(function (response) {
        const src = `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${response.data.video}`;

        setItems((prev) => [
          response.data.image1 && {
            id: 1,
            imgSrc: `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${response.data.image1}`,
            text: response.data.text1,
            link: response.data.link1,
          },

          response.data.image2 && {
            id: 2,
            imgSrc: `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${response.data.image2}`,
            text: response.data.text2,
            link: response.data.link2,
          },

          response.data.image3 && {
            id: 3,
            imgSrc: `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${response.data.image3}`,
            text: response.data.text3,
            link: response.data.link3,
          },

          response.data.image4 && {
            id: 4,
            imgSrc: `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${response.data.image4}`,
            text: response.data.text4,
            link: response.data.link4,
          },

          response.data.image5 && {
            id: 5,
            imgSrc: `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${response.data.image5}`,
            text: response.data.text5,
            link: response.data.link5,
          },
        ]);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error.response);
        setLoading(false);
      });
  };
  useEffect(() => {
    getPageData();
  }, []);

  // const items = [
  //   {
  //     name: "Random Name #1",
  //     description: "Probably the most random thing you have ever seen!",
  //   },
  //   {
  //     name: "Random Name #2",
  //     description: "Hello World!",
  //   },
  // ];

  const Item = ({ item }) => {
    return (
      <div style={{ position: "relative", zIndex: -1 }}>
        <a href={item?.link ?? ""} target="_blank" noreferrer noopener external>
          <Image
            style={{
              margin: "auto",
              height: "80vh",
              objectFit: "cover",
              zIndex: -1,
            }}
            src={item.imgSrc}
            alt=""
            width={"100%"}
            height={"100%"}
          />
        </a>
        <div
          style={{
            position: "absolute",
            top: matches ? "25%" : "25%",
            left: 20,
          }}
        >
          <div style={{fontSize:'35px'}}>
            <div dangerouslySetInnerHTML={{ __html: item.text }}/>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getPageData();
  }, []);

  return (
    <>
      <div
        className="carousel-container"
        style={{ paddingTop: "80px", zIndex: -1 }}
      >
        <Carousel  interval={'10000'} indicators={false}>
          {items?.map((item, i) => (
            <Item key={i} item={item} />
          ))}
        </Carousel>
      </div>
      <LinkSection />

      <ServicesSection />

      <ContactBanner />

      <NewsBrief />

      <EmployeesCarousel />

      <Partners />
    </>
  );
};

export default Home;
