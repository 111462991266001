import React from "react";
import styles from "../../../src/assets/styles/CustomHero.module.css";

const CustomHero = (props) => {
  return (
    <section
      className={styles.pageTitleSection}
      style={{
        backgroundImage: `url(
          ${props.image}
        )`,
      }}
    >
      <div className={styles.container}>
        <div className={styles.pageTitleInfo}>
          <h2>{props.title}</h2>
          <ol className={styles.breadcrumb}>
            <li>
              <a href="./">Home</a>
            </li>
            <li className={styles.active}>{props.page}</li>
          </ol>
        </div>
      </div>
    </section>
  );
};

export default CustomHero;
