import React, { useState } from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import useMediaQuery from "@mui/material/useMediaQuery";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputLabel,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import CustomDataTable from "../custom/CustomDataTable";
import ViewReports from "./ViewReports";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const PatientsTable = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const matches = useMediaQuery("(max-width:600px)");

  const [form, setForm] = useState({
    patient_id: "",
    patient_password: "",
    doctor_id: "",
    doctor_password: "",
  });

  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Lab Results",
    paragraph: "All results",
  };

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState("");
  const [visible, setVisible] = useState(false);

  const ViewPatientReports = (meta) => {
    setVisible(true);
    setId(meta.rowData[0]);
  };

  const handleClose = (refresh) => {
    setVisible(false);
    // if (refresh === true) {
    //   getData();
    // }
  };

  const onRowClick = (row) => {
    setVisible(true);
    setId(row[0]);
  };

  const columns = [
    {
      name: "PatientId",
      label: "Id",
    },

    {
      name: "Nom",
      label: "Patient",
    },

    {
      name: "actions",
      label: "View",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => {
          return (
            <div className="d-grid gap-2 d-md-flex">
              {meta.rowData[0] == 999 ? null : (
                <Button
                  color={"primary"}
                  size="sm"
                  // onClick={() => ViewPatientReports(meta)}
                >
                  View patient exams
                </Button>
              )}
            </div>
          );
        },
      },
    },
  ];
  const options = {
    onRowClick: onRowClick,
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [20, 50, 100],
    selectableRows: "none",
    // customToolbar: () => {
    //   return (
    //     <span>
    //       <Tooltip title={"Add"}>
    //         <IconButton onClick={() => console.log("add")}>
    //           <AddIcon />
    //         </IconButton>
    //       </Tooltip>
    //     </span>
    //   );
    // },
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <LinearProgress />
        ) : (
          "There is no matching data to display"
        ),
      },
    },
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="">
        <div className={innerClasses}>
          <SectionHeader
            data={{
              title: `Welcome`,
              paragraph: `${props.user?.name}`,
            }}
            className="center-content"
          />

          <CustomDataTable
            data={props.data}
            columns={columns}
            isLoading={isLoading}
            options={options}
          />

          <ViewReports
            visible={visible}
            id={id}
            doctor={props.user}
            handleClose={handleClose}
          />
        </div>
      </div>
    </section>
  );
};

PatientsTable.propTypes = propTypes;
PatientsTable.defaultProps = defaultProps;

export default PatientsTable;
