import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import axios from "axios";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const Partners = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "OUR INSURANCE PARTNERS",
    paragraph: "",
  };

  const [partners, setPartners] = useState([]);
  const getPartners = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/partners`, {
        params: {},
      })
      .then(function (response) {
        setPartners(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  useEffect(() => {
    getPartners();
  }, []);

  return (
    <section {...props} className={outerClasses} id="insurance-partners">
      <div className="">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            {partners?.map((partner) => (
              <div
                className="tiles-item reveal-from-bottom"
                style={{ flexBasis: "150px" }}
              >
                <div className="features-tiles-item-image mb-16">
                  <Image
                    src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/partnersImages/${partner?.image}`}
                    alt="ADIR"
                    width={150}
                    height={150}
                  />
                </div>
              </div>
            ))}
            {/*     <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_ADIR.png")}
                  alt="ADIR"
                  width={150}
                  height={150}
                />
              </div>
            </div>

          <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_Al_Mashrek.png")}
                  alt="Al Mashrek"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_ALIG.png")}
                  alt="ALIG"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_Allianz.png")}
                  alt="Allianz"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_Amana.png")}
                  alt="Amana"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_Arabia.png")}
                  alt="Arabia"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_Arope.png")}
                  alt="Arope"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_Assurex.png")}
                  alt="Assurex"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_Bankers.png")}
                  alt="Bankers"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_Berytus.png")}
                  alt="Berytus"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_Burgan.png")}
                  alt="Burgan"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_Capital.png")}
                  alt="Capita"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_Casino.png")}
                  alt="Casino"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_CIG.png")}
                  alt="CIG"
                  width={150}
                  height={150}
                />
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_CME.png")}
                  alt="CME"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_CMH.png")}
                  alt="CMH"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_CML.png")}
                  alt="CML"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_Commercial.png")}
                  alt="Commercial"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_Continental.png")}
                  alt="Continental"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_Cumberland.png")}
                  alt="Cumberland"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_Electricite_du_Liban.png")}
                  alt="Electricite du Liban"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_Enaya.png")}
                  alt="Enaya"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_Fajr_Al_Gulf.png")}
                  alt="Fajr Al Gulf"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_Fidelity.png")}
                  alt="Fidelity"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_Globemed.png")}
                  alt="Globemed"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_ISF_lebanon.png")}
                  alt="ISF lebanon"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_La_Phenicienne.png")}
                  alt="La Phenicienne"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_LIA.png")}
                  alt="LIA"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_Medgulf.png")}
                  alt="Medgulf"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_Mednet.png")}
                  alt="Mednet"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_NextCare.png")}
                  alt="NextCare"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_North_Assurance.png")}
                  alt="North Assurance"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_Securite.png")}
                  alt="Securite"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_UCA.png")}
                  alt="UCA"
                  width={150}
                  height={150}
                />
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              style={{ flexBasis: "150px" }}
            >
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/partners/thumbs_United_Assurance.png")}
                  alt="United Assurance"
                  width={150}
                  height={150}
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

Partners.propTypes = propTypes;
Partners.defaultProps = defaultProps;

export default Partners;
