import React, { useEffect, useState } from "react";
import { Route, Link, Routes, useParams } from "react-router-dom";
import Image from "../components/elements/Image";
// import sections
import Hero from "../components/sections/Hero";
import FeaturesTiles from "../components/sections/FeaturesTiles";
import FeaturesSplit from "../components/sections/FeaturesSplit";
import Testimonial from "../components/sections/Testimonial";
import Cta from "../components/sections/Cta";
import axios from "axios";
import NewsPage from "../components/sections/NewsPage";

const NewsBlog = () => {
  const params = useParams();

  console.log(params.category, params.id);

  const [news, setNews] = useState([]);

  const getNews = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/news/get/${params.id}`, {
        params: {},
      })
      .then(function (response) {
        setNews(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  useEffect(() => {
    getNews();
  }, []);

  return (
    <>
      <NewsPage news={news} />
    </>
  );
};

export default NewsBlog;
