import { Print } from "@material-ui/icons";
import {
  Box,
  CircularProgress,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import classNames from "classnames";

import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import CustomDataTable from "../components/custom/CustomDataTable";
import Image from "../components/elements/Image";

const Report = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const { PatientId, ExamId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [patient, setPatient] = useState(null);
  const [lipid, setLipid] = useState(false);
  const id = ExamId;
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const getData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/results/report/get`, {
        params: {
          PatientId: PatientId,
          ExamId: ExamId,
        },
      })
      .then((res) => {
        console.log(res.data);
        setData(res.data.report);
        setPatient(res.data.patient);
        setLipid(res.data.lipid == 1);
        setIsLoading(false);
      })
      .catch((err) => setIsLoading(false));
  };

  useEffect(() => {
    // window.open(
    //   `${process.env.REACT_APP_API_BASE_URL}/results/report/pdf/${id}`
    // );

    getData();
  }, []);

  const columns = [
    {
      name: "Type",
      label: "Test",
    },

    {
      name: "ResultAtLabo",
      label: "Result",
    },

    {
      name: "Unit",
      label: "Unit",
    },

    {
      name: "Normal",
      label: "Reference Value",
    },

    // {
    //   name: "Last",
    //   label: "Last Result",
    // },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    pagination: false,

    fixedHeader: true,
    fixedSelectColumn: true,
    tableBodyHeight: "100vh",
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <LinearProgress />
        ) : (
          "There is no matching data to display"
        ),
      },
    },
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <a
          href={`${process.env.REACT_APP_API_BASE_URL}/results/report/pdf/${ExamId}/${PatientId}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "#008cb7",
            textAlign: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Print style={{ marginRight: 10 }} /> Download PDF
        </a>

        <div>
          {/* <CustomDataTable
            data={data}
            columns={columns}
            isLoading={isLoading}
            options={options}
          /> */}

          <Image
            src={require("../assets/images/logo4.jpg")}
            alt="Open"
            // width={150}
            // height={150}
          />

          <Box sx={{ overflow: "auto" }}>
            <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colspan="3">Name: {patient?.Nom}</TableCell>
                    <TableCell colspan="2" align="right">
                      Year of birth: {patient?.Birthdate}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colspan="3">
                      Lab ID: {patient?.PatientId} / {id}
                    </TableCell>
                    <TableCell colspan="2" align="right">
                      Date: {patient?.ExamDate.split(" ")[0]}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    style={{
                      backgroundColor: "#008cb7",
                    }}
                  >
                    <TableCell align="center" style={{ color: "white" }}>
                      Test
                    </TableCell>
                    {/* <TableCell align="center" style={{ color: "white" }}>
                      Type
                    </TableCell> */}

                    <TableCell align="center" style={{ color: "white" }}>
                      Result
                    </TableCell>
                    <TableCell align="center" style={{ color: "white" }}>
                      Unit
                    </TableCell>
                    <TableCell align="center" style={{ color: "white" }}>
                      Normal
                    </TableCell>
                    {/* <TableCell align="center" style={{ color: "white" }}>
                      Last result
                    </TableCell> */}
                  </TableRow>
                </TableHead>
                {isLoading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        colspan="5"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {data?.map((row, index) => (
                      <>
                        {row.Category && row.data.length > 0 && (
                          <>
                            <TableRow key={index}>
                              <TableCell
                                colspan="5"
                                style={{
                                  textAlign: "center",
                                  backgroundColor: "#F4F4F4",
                                }}
                              >
                                <h5 style={{ margin: "auto" }}>
                                  {row.Category}
                                </h5>
                              </TableCell>
                            </TableRow>

                            {row.data?.map((record, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                {Object.keys(record)?.map((key, index) => (
                                  <TableCell
                                    key={index}
                                    align="center"
                                    sx={{ fontSize: 14, padding: 0 }}
                                  >
                                    {record[key]}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </>
                        )}
                      </>
                    ))}
                  </TableBody>
                )}
              </Table>
            </Box>
          </Box>

          {lipid && (
            <Image
              src={require("../assets/images/lipid.jpg")}
              alt="Open"
              // width={150}
              // height={150}
            />
          )}
          <div>
            <i>
              Examination reports without signature and seal are not considered
              official. This confidential report is intended for the named
              recipient. If you are not the intended recipient kindly contact
              sender and discard the report. Unauthorized use and/or disclosure
              of its contents is strictly prohibited.
            </i>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Report;
