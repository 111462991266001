import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import styles from "../../assets/styles/ContactBanner.module.css";
import PhoneIcon from "@mui/icons-material/Phone";
import { Link } from "react-router-dom";
const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const ContactBanner = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "OUR INSURANCE PARTNERS",
    paragraph: "",
  };

  return (
    <section class={styles.promotionWrapper}>
      <div class={styles.container}>
        <div class={styles.promotionInfo}>
          <h2>Need more information?</h2>

          <Link to="/contactus" class={`${styles.btn} ${styles.btn_primary}`}>
            <i class={`${styles.fa} ${styles.fa_phone}`} aria-hidden="true">
              <PhoneIcon sx={{ fontSize: 14 }} />
            </i>
            Contact
          </Link>
        </div>
      </div>
    </section>
  );
};

ContactBanner.propTypes = propTypes;
ContactBanner.defaultProps = defaultProps;

export default ContactBanner;
