import React, { useState } from "react";
// import sections
import Hero from "../components/sections/Hero";
import FeaturesTiles from "../components/sections/FeaturesTiles";
import FeaturesSplit from "../components/sections/FeaturesSplit";
import Testimonial from "../components/sections/Testimonial";
import Cta from "../components/sections/Cta";
import TabsForms from "../components/sections/TabsForms";
import PatientsTable from "../components/sections/PatientsTable";
import ReportsTable from "../components/sections/ReportsTable";
import { CircularProgress } from "@mui/material";

const Results = () => {
  const [data, setData] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  return (
    <>
      {/* <Hero className="illustration-section-01" /> */}
      {/* <FeaturesTiles /> */}
      {/* <FeaturesSplit
        invertMobile
        topDivider
        imageFill
        className="illustration-section-02"
      /> */}
      {!data ? (
        <TabsForms
          topDivider
          setData={setData}
          setLoading={setLoading}
          loading={loading}
          setUser={setUser}
        />
      ) : user?.user_type == 1 ? (
        <PatientsTable topDivider data={data} user={user} />
      ) : (
        <ReportsTable topDivider data={data} user={user} />
      )}
      {/* <Cta split /> */}
    </>
  );
};

export default Results;
