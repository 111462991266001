import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../utils/SectionProps";
import SectionHeader from "../components/sections/partials/SectionHeader";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import CustomHero from "../components/sections/CustomHero";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const Faqs = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: " ",
    paragraph: "",
  };

  const [faqs, setFaqs] = useState([]);
  const [data, setData] = useState({});

  const getFaqs = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/faqs/get`, {
        params: {},
      })
      .then(function (response) {
        setFaqs(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  const getPageData = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/pages/get`, {
        params: {
          name: "ContactUs",
        },
      })
      .then(function (response) {
        setData((prev) => ({
          ...prev,
          image: `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${response.data.image1}`,
          text: response.data.text1,
        }));
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  useEffect(() => {
    getFaqs();
    getPageData();
  }, []);

  return (
    <section {...props} className={outerClasses}>
      <CustomHero
        title="FREQUENTLY ASKED QUESTIONS"
        image={data?.image}
        page="Faqs"
      />

      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />

          <div>
            {faqs?.map((faq, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h4>{faq.question}</h4>
                </AccordionSummary>
                <AccordionDetails>
                  <h5 style={{color:'#666666',fontSize:'20px'}}>{faq.answer}</h5>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

Faqs.propTypes = propTypes;
Faqs.defaultProps = defaultProps;

export default Faqs;
