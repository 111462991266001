import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import styles from "../../assets/styles/EmployeesCarousel.module.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { style } from "@mui/system";
const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const EmployeesCarousel = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "OUR INSURANCE PARTNERS",
    paragraph: "",
  };

  const [employees, setEmployees] = useState([]);

  const getEmployees = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/employees/get`, {
        params: {},
      })
      .then(function (response) {
        setEmployees(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  useEffect(() => {
    getEmployees();
  }, []);

  const matches = useMediaQuery("(max-width:600px)");

  return (
    <section className={styles.promotionWrapper} id="ourTeam">
      <div className={styles.section}>
        <div className={styles.header}>
          <h1>Meet our team</h1>
        </div>
        <OwlCarousel
          items={matches ? 2 : 4}
          margin={10}
          center
          nav
          dots
          Autoplay
          loop
        >
          {employees?.map((employee) => (
            <div className={styles.card}>
              <img
                src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/employeesImages/${employee?.image}`}
                className={styles.img}
              />
              <h3>{employee.name}</h3>
              <h4>{employee.position}</h4>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </section>
  );
};

EmployeesCarousel.propTypes = propTypes;
EmployeesCarousel.defaultProps = defaultProps;

export default EmployeesCarousel;
