import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classNames from "classnames";
import styles from "../../assets/styles/NewsPage.module.css";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import Input from "../elements/Input";
import Button from "../elements/Button";
import axios from "axios";
import Carousel from "react-material-ui-carousel";
const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const ServicePage = ({
  service,
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: service?.title,
    paragraph: service?.short_description,
  };

  return (
    <section {...props} className={outerClasses}>
      <ToastContainer />
      <div className="container">
        <div className={innerClasses}>
          <div
            className="carousel-container"
            style={{ zIndex: -1 }}
          >
            <Carousel interval={'8000'} indicators={false}>
              <Image
                src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/servicesImages/${service?.image}`}
                alt="Open"
                width={"100%"}
                height={"100%"}
                style={{
                  margin: "auto",
                  height: "50vh",
                  objectFit: "cover",
                  zIndex: -1,
                }}
              />
              <Image
                src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/servicesImages/${service?.image2}`}
                alt="Open"
                width={"100%"}
                height={"100%"}
                style={{
                  margin: "auto",
                  height: "50vh",
                  objectFit: "cover",
                  zIndex: -1,
                }}
              />
              <Image
                src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/servicesImages/${service?.image3}`}
                alt="Open"
                width={"100%"}
                height={"100%"}
                style={{
                  margin: "auto",
                  height: "50vh",
                  objectFit: "cover",
                  zIndex: -1,
                }}
              />
            </Carousel>
          </div>
          {/* <div className={tilesClasses}>
            <Image
              src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/servicesImages/${service?.image}`}
              alt="Open"
            // width={150}
            // height={150}
            />
          </div> */}

          <SectionHeader data={sectionHeader} className="center-content" />
          <div
          dangerouslySetInnerHTML={{
            __html: service.description,
          }}
        />
        </div>
      </div>
    </section>
  );
};

ServicePage.propTypes = propTypes;
ServicePage.defaultProps = defaultProps;

export default ServicePage;
