import React from "react";
// import sections
import Hero from "../components/sections/Hero";
import FeaturesTiles from "../components/sections/FeaturesTiles";
import FeaturesSplit from "../components/sections/FeaturesSplit";
import Testimonial from "../components/sections/Testimonial";
import Cta from "../components/sections/Cta";
import useMediaQuery from "@mui/material/useMediaQuery";
import AboutUsSection from "../components/sections/AboutUsSection";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

const AboutUs = () => {
  const matches = useMediaQuery("(max-width:600px)");

  const [loading, setLoading] = useState("");
  const [data, setData] = useState({});

  const getPageData = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/pages/get`, {
        params: {
          name: "AboutUs",
        },
      })
      .then(function (response) {
        setData((prev) => ({
          ...prev,
          image: `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${response.data.image1}`,
          text1: response.data.text1,
          text2: response.data.text2,
        }));

        setLoading(false);
      })
      .catch(function (error) {
        console.log(error.response);
        setLoading(false);
      });
  };
  useEffect(() => {
    getPageData();
  }, []);

  return (
    <>
      <AboutUsSection data={data} />
    </>
  );
};

export default AboutUs;
