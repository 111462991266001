import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../utils/SectionProps";
import SectionHeader from "../components/sections/partials/SectionHeader";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { ContactForm } from "../components/custom/ContactForm";
import { Box, Grid } from "@mui/material";
import Image from "../components/elements/Image";
import CustomHero from "../components/sections/CustomHero";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const ContactUs = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: " ",
    paragraph: "",
  };
  const [data, setData] = useState({});
  const getPageData = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/pages/get`, {
        params: {
          name: "ContactUs",
        },
      })
      .then(function (response) {
        setData((prev) => ({
          ...prev,
          image: `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${response.data.image1}`,
          text: response.data.text1,
        }));
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };
  useEffect(() => {
    getPageData();
  }, []);

  return (
    <section {...props} className={outerClasses}>
      <CustomHero title="CONTACT US" image={data?.image} page="Contact" />

      <div className="container">
        <div className={innerClasses}>
          {/* <Image
            src={require("../assets/images/contactus.jpg")}
            alt="Contact Us"
            style={{ marginBottom: 20 }}
             
          /> */}

          <SectionHeader data={sectionHeader} className="center-content" />

          <Grid
            container
            spacing={4}
            // justifyContent="center"
            // alignItems="center"
          >
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <h3 class="widget-title">Get in touch</h3>
              <ContactForm />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
              <div class="textwidget">
                <p></p>
                <aside class="widget mtphr-contact-widget">
                  <h3 class="widget-title">Contact Details</h3>
                  <table>
                    <tbody>
                      <tr class="mtphr-contact-widget-info">
                        <td class="mtphr-contact-widget-title">Address:</td>
                        <td class="mtphr-contact-widget-description">
                          Centre St. Elie, Antelias – Block B
                        </td>
                      </tr>
                      <tr class="mtphr-contact-widget-info">
                        <td class="mtphr-contact-widget-title">Tel:</td>
                        <td class="mtphr-contact-widget-description">
                          04 523 883 / 6
                        </td>
                      </tr>
                      <tr class="mtphr-contact-widget-info">
                        <td class="mtphr-contact-widget-title">Mob:</td>
                        <td class="mtphr-contact-widget-description">
                          03 149 913
                        </td>
                      </tr>
                      <tr class="mtphr-contact-widget-info">
                        <td class="mtphr-contact-widget-title">Fax:</td>
                        <td class="mtphr-contact-widget-description">
                          04 523 884
                        </td>
                      </tr>
                      <tr class="mtphr-contact-widget-info">
                        <td class="mtphr-contact-widget-title">Email:</td>
                        <td class="mtphr-contact-widget-description">
                          <a href="mailto:stelielab@gmail.com">
                            <h6 style={{ margin: 0 }}> stelielab@gmail.com</h6>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </aside>
                <p></p>
                <p>
                  <span
                    class=""
                    // style="display:block;clear:both;height: 0px;padding-top: 20px;border-top-width:0px;border-bottom-width:0px;"
                  ></span>
                </p>
                <p>&nbsp;</p>
                <p></p>
                <aside class="widget mtphr-contact-widget">
                  <h3 class="widget-title">Office Hours</h3>
                  <table>
                    <tbody>
                      <tr class="mtphr-contact-widget-info">
                        <td class="mtphr-contact-widget-title">Mon to Fri:</td>
                        <td class="mtphr-contact-widget-description">
                          7 am to 6 pm
                        </td>
                      </tr>
                      <tr class="mtphr-contact-widget-info">
                        <td class="mtphr-contact-widget-title">Saturday:</td>
                        <td class="mtphr-contact-widget-description">
                          7 am to 1 pm
                        </td>
                      </tr>
                      <tr class="mtphr-contact-widget-info">
                        <td class="mtphr-contact-widget-title">Sunday:</td>
                        <td class="mtphr-contact-widget-description">Closed</td>
                      </tr>
                    </tbody>
                  </table>
                </aside>
                <p></p>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </section>
  );
};

ContactUs.propTypes = propTypes;
ContactUs.defaultProps = defaultProps;

export default ContactUs;
