import LinearProgress from "@material-ui/core/LinearProgress";
import { Close } from "@material-ui/icons";
import { Box, Button, Modal } from "@mui/material";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CustomDataTable from "../custom/CustomDataTable";

const ViewReports = (props) => {
  let history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.visible) {
      if (props.id > 0) {
        getData();
      }
    }
  }, [props.visible]);

  const getData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/results/reports/get`, {
        params: {
          PatientId: props.id,
          DoctorId: props.doctor.user_id,
        },
      })
      .then((res) => {
        console.log(res.data);
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => setIsLoading(false));
  };

  const viewReport = (meta) => {
    const id = meta.rowData[1];

    const patient_id = meta.rowData[0];

    const exam_id = meta.rowData[2];

    // history.push(`/report/${id}`);
    window.open(`/report/${patient_id}/${exam_id}`, "_blank");
  };

  const onRowClick = (row) => {
    // const id = row[1];

    const patient_id = row[0];

    const exam_id = row[2];

    window.open(`/report/${patient_id}/${exam_id}`, "_blank");
  };

  const columns = [
    {
      name: "PatientId",
      label: "ID",
    },
    {
      name: "Patient",
      label: "Patient",
    },

    {
      name: "ExamId",
      label: "Exam ID",
    },

    {
      name: "ExamDate",
      label: "Exam Request Date",
    },
    {
      name: "actions",
      label: "View",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => {
          return (
            <div className="d-grid gap-2 d-md-flex">
              <Button
                color={"primary"}
                size="sm"
                // onClick={() => {
                //   viewReport(meta);
                // }}
              >
                View report
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    onRowClick: onRowClick,
    filterType: "dropdown",
    selectableRows: "none",
    customToolbar: () => {
      return (
        <span>
          <Tooltip title={"Close"}>
            <IconButton onClick={() => props.handleClose()}>
              <Close />
            </IconButton>
          </Tooltip>
        </span>
      );
    },

    textLabels: {
      body: {
        noMatch: isLoading ? (
          <LinearProgress />
        ) : (
          "There is no matching data to display"
        ),
      },
    },
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    // height: "100%",
    bgcolor: "transparent",
    // border: "2px solid #000",
    // boxShadow: 24,
    // p: 4,
  };

  return (
    <Modal size="xl" open={props.visible} onClose={() => props.handleClose()}>
      <Box sx={style}>
        <div className="container" style={{}}>
          <CustomDataTable
            data={data}
            columns={columns}
            isLoading={isLoading}
            options={options}
          />
        </div>
      </Box>
    </Modal>
  );
};
ViewReports.propTypes = {
  visible: PropTypes.any.isRequired,
  id: PropTypes.any,
  handleClose: PropTypes.func.isRequired,
};

export default ViewReports;
