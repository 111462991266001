import React, { useState } from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import useMediaQuery from "@mui/material/useMediaQuery";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
// import Box from "@mui/material/Box";
import { Box, CircularProgress, Modal } from "@mui/material";
import {
  Button,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
} from "@mui/material";
import axios from "axios";

import { useSelector, useDispatch } from "react-redux";
import { setUserData } from "../../features/user/userSlice";
import { useEffect } from "react";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const TabsForms = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const state = useSelector((state) => state.user.data);

  const dispatch = useDispatch();

  const matches = useMediaQuery("(max-width:600px)");

  const [form, setForm] = useState({
    patient_id: "",
    patient_password: "",
    doctor_id: "",
    doctor_password: "",
  });

  const [user, setUser] = useState(0);

  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader1 = {
    title: "Lab Results",
    paragraph: "Choose an option",
  };
  const sectionHeader2 = {
    title: "Lab Results",
    paragraph: "Enter your Id and Password",
  };

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const handleInputChange = (e) => {
    const { value, id } = e.target;

    setForm((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const submit = () => {
    props.setLoading(true);

    if (user == 1 || state.user_type == 1) {
      if (!state.user_id)
        dispatch(
          setUserData({
            user_id: form.doctor_id,
            password: form.doctor_password,
            user_type: 1,
          })
        );
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/results/get`, {
          params: {
            user_id: state.user_id ?? form.doctor_id,
            password: state.password ?? form.doctor_password,
            user_type: 1,
          },
        })
        .then((res) => {
          props.setData(res.data.patients);
          props.setUser(res.data.doctor);
        })
        .catch((err) => console.log(err.respone))
        .finally(() => {
          props.setLoading(false);
        });
    } else {
      if (!state.user_id)
        dispatch(
          setUserData({
            user_id: form.patient_id,
            password: form.patient_password,
            user_type: 2,
          })
        );

      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/results/get`, {
          params: {
            user_id: state.user_id ?? form.patient_id,
            password: state.password ?? form.patient_password,
            user_type: 2,
          },
        })
        .then((res) => {
          props.setData(res.data.reports);
          props.setUser(res.data.patient);
        })
        .catch((err) => console.log(err.respone))
        .finally(() => {
          props.setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (state.user_id) {
      submit();
    }
  }, []);

  const [modalVisible, setModalVisible] = useState(!state.user_id);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: "100%",
    minWidth: "400px",
    padding: "40px",

    bgcolor: "white",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader2} className="center-content" />
          <Modal size="xl" open={modalVisible} onClose={() => {}}>
            <Box sx={style}>
              <SectionHeader data={sectionHeader1} className="center-content" />
              {user == 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    maxWidth: 400,
                    margin: "auto",
                  }}
                >
                  <button
                    style={{ marginBottom: 20 }}
                    onClick={() => {
                      setUser(1);
                      setModalVisible(false);
                    }}
                  >
                    <h4>I am a DOCTOR</h4>
                  </button>

                  <button
                    style={{ marginBottom: 20 }}
                    onClick={() => {
                      setUser(2);
                      setModalVisible(false);
                    }}
                  >
                    <h4>I am a PATIENT</h4>
                  </button>
                </div>
              )}
            </Box>
          </Modal>

          {user == 1 && (
            <div
              style={{
                justifyContent: "space-around",
                display: "flex",
                flexDirection: "column",
                maxWidth: 500,
                margin: "auto",
              }}
            >
              <FormControl>
                <InputLabel htmlFor="doctor_id">Doctor ID</InputLabel>
                <Input
                  style={{ marginBottom: 20 }}
                  id="doctor_id"
                  aria-describedby="my-helper-text"
                  onChange={handleInputChange}
                  value={form.doctor_id}
                />
                <FormHelperText id="my-helper-text">
                  {/* Enter the doctor id */}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="doctor_password">
                  Doctor Password
                </InputLabel>
                <Input
                  style={{ marginBottom: 20 }}
                  id="doctor_password"
                  aria-describedby="my-helper-text"
                  onChange={handleInputChange}
                  value={form.doctor_password}
                />
                <FormHelperText id="my-helper-text">
                  {/* Enter the doctor password */}
                </FormHelperText>
              </FormControl>
            </div>
          )}

          {user == 2 && (
            <div
              style={{
                justifyContent: "space-around",
                display: "flex",
                flexDirection: "column",
                maxWidth: 500,
                margin: "auto",
              }}
            >
              <FormControl>
                <InputLabel htmlFor="patient_id">Patient ID</InputLabel>
                <Input
                  style={{ marginBottom: 20 }}
                  id="patient_id"
                  aria-describedby="my-helper-text"
                  onChange={handleInputChange}
                  value={form.patient_id}
                />
                <FormHelperText id="my-helper-text">
                  {/* Enter the patient id */}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="patient_password">
                  Patient Password
                </InputLabel>
                <Input
                  style={{ marginBottom: 20 }}
                  id="patient_password"
                  aria-describedby="my-helper-text"
                  onChange={handleInputChange}
                  value={form.patient_password}
                />
                <FormHelperText id="my-helper-text">
                  {/* Enter the patient password */}
                </FormHelperText>
              </FormControl>
            </div>
          )}

          {/* <Box sx={{ bgcolor: "background.paper" }}>
            <AppBar position="static">
              <Tabs
                TabIndicatorProps={{ style: { background: "white" } }}
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab
                  style={{ backgroundColor: "#008cb7" }}
                  label="Doctor"
                  {...a11yProps(0)}
                />
                <Tab
                  style={{ backgroundColor: "#008cb7" }}
                  label="Patient"
                  {...a11yProps(1)}
                />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <div
                  style={{
                    justifyContent: "space-around",
                    display: "flex",
                    flexDirection: matches ? "column" : "row",
                  }}
                >
                  <FormControl>
                    <InputLabel htmlFor="doctor_id">Doctor ID</InputLabel>
                    <Input
                      id="doctor_id"
                      aria-describedby="my-helper-text"
                      onChange={handleInputChange}
                      value={form.doctor_id}
                    />
                    <FormHelperText id="my-helper-text">
                      Enter the doctor id
                    </FormHelperText>
                  </FormControl>
                  <FormControl>
                    <InputLabel htmlFor="doctor_password">
                      Doctor Password
                    </InputLabel>
                    <Input
                      id="doctor_password"
                      aria-describedby="my-helper-text"
                      onChange={handleInputChange}
                      value={form.doctor_password}
                    />
                    <FormHelperText id="my-helper-text">
                      Enter the doctor password
                    </FormHelperText>
                  </FormControl>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <div
                  style={{
                    justifyContent: "space-around",
                    display: "flex",
                    flexDirection: matches ? "column" : "row",
                  }}
                >
                  <FormControl>
                    <InputLabel htmlFor="patient_id">Patient ID</InputLabel>
                    <Input
                      id="patient_id"
                      aria-describedby="my-helper-text"
                      onChange={handleInputChange}
                      value={form.patient_id}
                    />
                    <FormHelperText id="my-helper-text">
                      Enter the patient id
                    </FormHelperText>
                  </FormControl>
                  <FormControl>
                    <InputLabel htmlFor="patient_password">
                      Patient Password
                    </InputLabel>
                    <Input
                      id="patient_password"
                      aria-describedby="my-helper-text"
                      onChange={handleInputChange}
                      value={form.patient_password}
                    />
                    <FormHelperText id="my-helper-text">
                      Enter the patient password
                    </FormHelperText>
                  </FormControl>
                </div>
              </TabPanel>
            </SwipeableViews>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <Button variant="outlined" onClick={submit}>
                Submit
              </Button>
            </div>
          </Box> */}

          {user != 0 && (
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                marginTop: 20,
              }}
            >
              {props.loading ? (
                <CircularProgress />
              ) : (
                <Button variant="outlined" onClick={submit}>
                  Submit
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

TabsForms.propTypes = propTypes;
TabsForms.defaultProps = defaultProps;

export default TabsForms;
