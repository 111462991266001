import { Button, TextField } from "@material-ui/core";
import React from "react";
import { useFormControls } from "./ContactFormControls";

const inputFieldValues = [
  {
    name: "full_name",
    label: "Your Name (required)",
    id: "full_name",
  },
  {
    name: "email",
    label: "Email",
    id: "email",
  },
  {
    name: "phone",
    label: "Telephone",
    id: "phone",
  },
  {
    name: "message",
    label: "Your Message (required)",
    id: "message",
    multiline: true,
    rows: 10,
  },
];

export const ContactForm = () => {
  const { handleInputValue, handleFormSubmit, formIsValid, errors } =
    useFormControls();

  return (
    <form autoComplete="off" onSubmit={handleFormSubmit}>
      {inputFieldValues.map((inputFieldValue, index) => {
        return (
          <TextField
            style={{ marginBottom: 10 }}
            key={index}
            onChange={handleInputValue}
            onBlur={handleInputValue}
            name={inputFieldValue.name}
            label={inputFieldValue.label}
            error={errors[inputFieldValue.name]}
            multiline={inputFieldValue.multiline ?? false}
            fullWidth
            rows={inputFieldValue.rows ?? 1}
            autoComplete="none"
            {...(errors[inputFieldValue.name] && {
              error: true,
              helperText: errors[inputFieldValue.name],
            })}
          />
        );
      })}
      <Button
        variant="contained"
        type="submit"
        color="inherit"
        disabled={!formIsValid()}
      >
        Send Message
      </Button>
    </form>
  );
};
