import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import styles from "../../assets/styles/NewsBriefSection.module.css";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import axios from "axios";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const NewsBrief = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Workflow that just works",
    paragraph:
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum — semper quis lectus nulla at volutpat diam ut venenatis.",
  };

  const [news, setNews] = useState([]);

  const getNews = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/news/brief/get`, {
        params: {},
      })
      .then(function (response) {
        setNews(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  useEffect(() => {
    getNews();
  }, []);

  return (
    // <section {...props} className={outerClasses}>
    //   <div className="container">
    //     {news?.length > 0 &&
    //       news?.map((section, index) => (
    //         <div className={innerClasses} key={index}>
    //           <SectionHeader
    //             data={{
    //               title: Object.keys(section)[0],
    //               paragraph: "",
    //             }}
    //             className="center-content"
    //           />

    //           <div className={splitClasses}>
    //             <div className="split-item">
    //               <div
    //                 className="split-item-content center-content-mobile reveal-from-right"
    //                 data-reveal-container=".split-item"
    //               >
    //                 <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
    //                   {section[Object.keys(section)[0]][0]?.news_title}
    //                 </div>
    //                 <h3 className="mt-0 mb-12">
    //                   {" "}
    //                   {section[Object.keys(section)[0]][0]?.news_title}
    //                 </h3>
    //                 {/* <p className="m-0">
    //                   {section[Object.keys(section)[0]][0]?.news_description}
    //                 </p> */}

    //                 <div
    //                   dangerouslySetInnerHTML={{
    //                     __html:
    //                       section[Object.keys(section)[0]][0]?.news_description,
    //                   }}
    //                 />
    //               </div>
    //               <div
    //                 className={classNames(
    //                   "split-item-image center-content-mobile reveal-from-bottom",
    //                   imageFill && "split-item-image-fill"
    //                 )}
    //                 data-reveal-container=".split-item"
    //               >
    //                 <Image
    //                   src={`${
    //                     process.env.REACT_APP_API_BASE_URL_ATTACHMENTS
    //                   }/newsImages/${
    //                     section[Object.keys(section)[0]][0]?.news_image
    //                   }`}
    //                   alt=""
    //                   width={528}
    //                   height={396}
    //                 />
    //               </div>
    //             </div>
    //           </div>
    //           <div style={{ textAlign: "center" }}>
    //           <Link to={`/news/${category.id}`}>
    //           <p>Read more</p>
    //           </Link>
    //           </div>
    //         </div>
    //       ))}
    //   </div>
    // </section>
    <section
      className={`${styles.whiteSection} ${styles.full_width} ${styles.clearfix} ${styles.coursesSection}`}
      id="News"
    >
      {/* {news?.length > 0 &&
        news?.map((section, index) => ( */}
      <div className={styles.container}>
        <div className={`${styles.sectionTitle} ${styles.text_center}`}>
          <h2>
            <span
              className={`${styles.shape} ${styles.shape_left} ${styles.bg_color_4}`}
            ></span>
            {/* <span>{Object.keys(section)[0]} News</span> */}
            <span> News</span>
            <span
              className={`${styles.shape} ${styles.shape_right} ${styles.bg_color_4}`}
            ></span>
          </h2>
        </div>

        <div className={styles.row}>
          {/* {section[Object.keys(section)[0]].map((card, index) => ( */}
          {news?.map((card, index) => (
            <div
              className={`${styles.col_sm_3} ${styles.col_xs_12} ${styles.block}`}
            >
              <div className={`${styles.thumbnail} ${styles.thumbnailContent}`}>
                {/* <Link to={`/news/${Object.keys(section)[0]}/${card.news_id}`}> */}
                <Link to={`/news/${card.category_name}/${card.news_id}`}>
                  {/* <a href=""> */}
                  <img
                    src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/newsImages/${card?.news_image}`}
                    alt="image"
                    className={styles.img_responsive}
                  />
                  {/* </a> */}
                </Link>
                <div className={`${styles.caption} ${styles.border_color_1}`}>
                  <h3>
                    <a href="" className={styles.color_1}>
                      {card?.news_title}
                    </a>
                  </h3>
                  <p>{card?.news_short_description}</p>
                  <ul className={`${styles.list_inline} ${styles.btn_yellow}`}>
                    <li>
                      <Link
                        to={`/news/${card.category_name}/${card.news_id}`}
                        className={`${styles.btn} ${styles.btn_link}`}
                      >
                        <i
                          className={`${styles.fa} ${styles.fa_angle_double_right}`}
                          aria-hidden="true"
                        >
                          <KeyboardDoubleArrowRightIcon
                            fontSize="small"
                            sx={{ fontSize: 11 }}
                          />
                        </i>
                        Show more
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/*   ))} */}
    </section>
  );
};

NewsBrief.propTypes = propTypes;
NewsBrief.defaultProps = defaultProps;

export default NewsBrief;
