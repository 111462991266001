import React from "react";
// import sections
import PartnersSection from "../components/sections/Partners";

const Partners = () => {
  return (
    <>
      <PartnersSection />
    </>
  );
};

export default Partners;
