import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ServicePage from "../components/sections/ServicePage";

const Service = () => {
  const params = useParams();

  console.log(params.id);

  const [service, setService] = useState({});

  const getService = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/data/services/get/${params.id}`,
        {
          params: {},
        }
      )
      .then(function (response) {
        setService(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  useEffect(() => {
    getService();
  }, []);

  return (
    <>
      <ServicePage service={service} />
    </>
  );
};

export default Service;
